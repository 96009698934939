<template>
  <v-app class="compalinace-content">
    <div class="content-container" v-if="pricing">
      <h1 class="gradient-text main-heading text-center">
        <img :src="require('@/assets/logo.svg')" />
        <span>Kerala Library Pricing</span>
      </h1>

      <v-row class="mt-7">
        <v-col class="d-flex justify-end">
          <v-card tile elevation="9" class="pricing" width="400">
            <v-app-bar color="primary" class="mb-4 heading">
              <div class="price free"><span>₹0</span></div>
              <span class="name">Free Trail</span>
              <span class="desc">Expires in 30 days</span>
            </v-app-bar>
            <p class="content-text"></p>
            <ul class="features-list px-8">
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Manage distributions of books
              </li>
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Keeps records of members fee dues
              </li>
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Download reports as excel and pdf
              </li>
              <li>
                <v-icon class="orange--text">mdi-check</v-icon>
                Save books data (Limited to
                {{ pricing.freeTrailLimit }} books)
              </li>
              <li>
                <v-icon class="orange--text">mdi-check</v-icon>
                Save members data (Limited to
                {{ pricing.freeTrailLimit }} members)
              </li>
              <li>
                <v-icon class="red--text">mdi-close</v-icon>
                Generate QR code for each book
              </li>
              <li>
                <v-icon class="red--text">mdi-close</v-icon>
                Bulk upload library data
              </li>
              <li>
                <v-icon class="red--text">mdi-close</v-icon>
                Show your books in public search results
              </li>
              <li>
                <v-icon class="red--text">mdi-close</v-icon>
                Create more than one users
              </li>
            </ul>
          </v-card></v-col>
        <v-col class="d-flex justify-start">
          <v-card tile elevation="9" class="pricing" width="400">
            <v-app-bar color="success" class="mb-4 heading flex-column">
              <div class="price premium">
                <span>₹{{ pricing.upgradePrice }}</span>
              </div>
              <span class="name">Premium</span>
              <span class="desc">Expires in an year, ₹{{ pricing.renewPrice }} to renew</span>
            </v-app-bar>
            <p class="content-text"></p>
            <ul class="features-list px-8">
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Manage distributions of books
              </li>
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Keeps records of members fee dues
              </li>
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Download reports as excel and pdf
              </li>
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Save books data (No limits)
              </li>
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Save members data (No limits)
              </li>
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Generate QR code for each book
              </li>
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Bulk upload library data
              </li>
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Show your books in public search results
              </li>
              <li>
                <v-icon class="green--text">mdi-check</v-icon>
                Create more than one users
              </li>
            </ul>
          </v-card></v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import store from "@/store";

export default {
  computed: {
    pricing() {
      return store.app.pricing;
    }
  }
};
</script>

<style scoped lang="scss">
body {
  margin: 0;
}

.main-heading {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100px;
    margin-right: 10px;
  }

  span {
    padding-top: 15px;
  }
}

.gradient-text {
  background: linear-gradient(180deg, #0899ec, #15b998);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.1;
}

.header-conatiner {
  display: flex;
  align-items: center;
  padding: 24px;
}

.header-text {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin: 0 0 0 18px;
}

.compalinace-page {
  background: #0a1d38;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 887px) {

  .justify-end,
  .justify-start {
    justify-content: center !important;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px) {
  .content-container {
    margin: 0 auto;
    max-width: 1000px;
  }

  .header-conatiner {
    margin: 0 auto;
    max-width: 1000px;
  }
}

.compalinace-content {
  background-color: transparent;

  ::v-deep .v-application--wrap {
    padding: 32px;
    display: block;
    min-height: auto;
  }
}

.content-head {
  font-size: 24px;
  line-height: 30px;
  color: #213554;
  margin: 0;
  font-weight: 700;
}

.updated-date {
  margin: 16px 0 0;
  color: #213554ab;
  font-weight: 700;
}

.content-text {
  color: #515978;
  margin: 16px 0 0;
}

.content-text {
  font-size: 14px;
  line-height: 20px;
}

.merchant-logo {
  width: 74.6px;
  height: 64px;
  background: #fefefe;
  box-shadow: 0px 0px 8px #00000040;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 330px) {
  .merchant-logo {
    width: 87px;
  }
}

@media all and (min-width: 768px),
(min-width: 383px) {
  .merchant-logo {
    width: 64px;
  }
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #0a1d38;
  box-shadow: 11.0003px 22.0007px 53.9016px rgba(0, 0, 0, 0.1),
    22.0007px 11.0003px 53.9854px -2.03719px rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.logo-text {
  color: #fff;
  font-weight: 700;
  font-size: 32px;
}

.list-item {
  display: list-item;
  padding-left: 5px;
}

.unorder-list {
  margin-left: 25px;
}

.list-text {
  margin-top: 8px;
}

.pricing {
  margin-top: 50px;

  .heading {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 75px !important;

    ::v-deep .v-toolbar__content {
      flex-direction: column;
      justify-content: center;

      span {
        line-height: 1;
      }
    }

    .name {
      font-weight: bold;
      font-size: 23px;
      margin-top: 15px;
      margin-bottom: 4px;
    }

    .price {
      position: absolute;
      background-color: #02b924;
      color: #333;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -60px;
      box-shadow: #0000007b 0 0 7px;
      left: calc(50% - 35px);
      color: #fff;

      &.free {
        background: linear-gradient(53deg, #0899ec, #15b998);

        span {
          margin-left: -3px;
        }
      }

      &.premium {
        background: linear-gradient(12deg, #00bf23, #01800e);
      }
    }
  }

  .features-list {
    text-align: left;
    max-width: 400px;
    padding: 12px 0;
    list-style: none;
    color: #7a7a7a;
    padding-bottom: 30px;

    li {
      display: flex;
      align-items: flex-start;
      margin-top: 5px;
      display: flex;
      align-items: center;
      line-height: 1.4;
      font-size: 15px;

      i {
        margin-right: 6px;
      }
    }
  }
}
</style>
